.image-container {
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 0 10px 10px 0;
}

.image-container img {
    display: block;
    max-width: 100%;
    height: auto;
    width: 100%
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* Прозрачный белый фон */
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-container:hover .overlay {
    opacity: 1;
}

.more-button {
    background-color: #e7c9c9;
    border: none;
    color: black;
    padding: 10px 20px;
    font-size: 28px;
    cursor: pointer;
}

.more-button:hover {

}

.portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -5px; /* Чтобы учесть отступы между изображениями */
}

.image-container {
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 0 10px 10px 0;
    width: calc(50% - 10px); /* По два в ряд на больших экранах */
}

/* Медиа-запрос для мобильных экранов */
@media (max-width: 767px) {
    .image-container {
        width: 100%; /* По одному в ряд на мобильных экранах */
    }
}


.image-container img {
    display: block;
    max-width: 100%;
    height: 100%; /* Задаем высоту 100% для сохранения пропорций */
    object-fit: cover; /* Заполняем контейнер, обрезая лишнее */
}

/*.wrapper {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*}*/

/*.cta {*/
/*    display: flex;*/
/*    padding: 10px 45px;*/
/*    text-decoration: none;*/
/*    font-family: 'Poppins', sans-serif;*/
/*    font-size: 40px;*/
/*    color: white;*/
/*    background: #6225E6;*/
/*    transition: 1s;*/
/*    box-shadow: 6px 6px 0 black;*/
/*    transform: skewX(-15deg);*/
/*}*/

/*.cta:focus {*/
/*    outline: none;*/
/*}*/

/*.cta:hover {*/
/*    transition: 0.5s;*/
/*    box-shadow: 10px 10px 0 #FBC638;*/
/*}*/

/*.cta span:nth-child(2) {*/
/*    transition: 0.5s;*/
/*    margin-right: 0px;*/
/*}*/

/*.cta:hover  span:nth-child(2) {*/
/*    transition: 0.5s;*/
/*    margin-right: 45px;*/
/*}*/

/*span {*/
/*    transform: skewX(15deg)*/
/*}*/

/*span:nth-child(2) {*/
/*    width: 20px;*/
/*    margin-left: 30px;*/
/*    position: relative;*/
/*    top: 12%;*/
/*}*/

/*!**************SVG****************!*/

/*path.one {*/
/*    transition: 0.4s;*/
/*    transform: translateX(-60%);*/
/*}*/

/*path.two {*/
/*    transition: 0.5s;*/
/*    transform: translateX(-30%);*/
/*}*/

/*.cta:hover path.three {*/
/*    animation: color_anim 1s infinite 0.2s;*/
/*}*/

/*.cta:hover path.one {*/
/*    transform: translateX(0%);*/
/*    animation: color_anim 1s infinite 0.6s;*/
/*}*/

/*.cta:hover path.two {*/
/*    transform: translateX(0%);*/
/*    animation: color_anim 1s infinite 0.4s;*/
/*}*/

/*!* SVG animations *!*/

/*@keyframes color_anim {*/
/*    0% {*/
/*        fill: white;*/
/*    }*/
/*    50% {*/
/*        fill: #FBC638;*/
/*    }*/
/*    100% {*/
/*        fill: white;*/
/*    }*/
/*}*/

.image-container img {
    border-radius: 5px;
}

.portfolio {
    margin-top: 20px;
}