.building {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    overflow: hidden;
}

.images {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 20px;
    text-align: center;
}

.building-image {
    max-width: 100%;
}

.stages {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.stage-card {
    border: 1px solid #030303;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stage-card:hover {
    background-color: #f0f0f0;
}

.stage-card.active {
    border: none;
    background-color: #74706f;;
    color: white;
}

/* Остальные стили остаются без изменений */

.building-image {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s, max-height 0.3s ease-in-out;
}

.building-image.active {
    opacity: 1;
    max-height: 100%;
}

.stage-description {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.stage-description.active {
    max-height: 100px; /* Adjust the value as needed */
}

/* Остальные стили остаются без изменений */

@media (max-width: 767px) {
    .building {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .images {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-bottom: 20px;
    }

    .stages {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        padding: 20px;
    }
}
