
@keyframes moveSlideshow {
    100% {
        transform: translateX(-1000px);
    }
}




/*Main*/

.values {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Три элемента в ряд */
    text-align: center;
}

.value-item {
    border-radius: 2px;
    border: 1px solid #030303;
    padding: 0.5rem;
    margin: 0.5rem;
}

.value-item:hover {
    background-color: #f0f0f0;
    color: #030303;
}

.image-column {
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width: 767px) {
    .values {
        grid-template-columns: repeat(1, 1fr); /* Один элемент в ряд */
    }
}

.card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: transform 0.2s;
}

.card:hover {
    transform: scale(1.02);
}

.card h2 {
    margin-top: 0;
}

.expanded {
    transform: scale(1.02);
    background-color: #f0f0f0;
}

.persons {
    padding-bottom: 200px;
}


@media (max-width: 768px) {
    .values {
        grid-template-columns: repeat(2, 1fr); /* Три элемента в ряд */
    }

    .main-mobile {
        padding-top: 20px;
        background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url("../assets/image.jpeg");
        background-size: cover;
        background-repeat: no-repeat;
        height: 100vh;
        color: white !important;
    }

    .value-item {
        border: 1px solid white;
    }

    .value-item:hover {
        color: black;
    }

    .image-column {
        display: none;
    }

}