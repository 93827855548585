.person-card {
    display: flex;
    align-items: flex-end;
    padding: 20px;

    max-width: 400px;
    margin: 20px auto;
    position: relative;
}

.person-image {
    flex: 1;
    position: relative;
}

.person-image img {
    max-width: 100%;
    border-radius: 2%;
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);*/
}

.person-info {
    flex: 2;
    position: absolute;
    z-index: 10;
    background-color: #74706f;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
}

.person-info-right {
    bottom: -20px;
    right: -30px;
}

.person-info-left {
    bottom: -20px;
    left: -30px;
}

.person-info h2 {
    margin: 0;
    font-size: 1.5rem;
}

.person-info p {
    margin: 8px 0;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .person-info-right {
        right: 0;
    }

.person-info-left {

        left: 0;
    }


}