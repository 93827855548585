.program-card {
    display: inline-flex; /* Используем flex контейнер */
    align-items: center; /* Выравнивание по вертикали */
    padding: 10px;
    margin: 0 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100px;
}

.program-card img {
    max-width: 300px;
    display: block;
}