
@font-face {

  font-family: 'Montserrat';
  src: local('Raleway'), url('../assets/font/Montserrat-VariableFont_wght.ttf') format('opentype'),
       local('Raleway'), url('../assets/font/Montserrat-Italic-VariableFont_wght.ttf') format('opentype');
  font-display: swap;
}

* {
  font-family: 'Montserrat', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    max-width: 100vw;
}


.navbar-custom {
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(3,3,3,0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-right: 5%;
  padding-left: 5%;
  position: fixed;
  z-index: 10;
}


.big-link {
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.link {
  position: relative;
  text-decoration: none;
  color: #fff;

  display: inline-block;
  padding-bottom: 5px;
}

.link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  height: 2px;
  width: 0;
  background: #e7c9c9;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

.link:hover::after {
  width: 100%;
  left: 0;
}


.mvm-text {
  color: #81182c;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;

}


.navbar-custom ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-custom li {
  display: inline-block;
  margin-left: 1rem;
}

.navbar-custom a {
  text-decoration: none;
  color: #8a8a8a;
  text-align: center;
  position: relative;
}


.contact-us {
  padding: 8px;
  color: black;
  transition: all 0.5s;
  position: relative;
  border-radius: 2px;
}

.contact-us::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(231,201,201,0.3);

  transition: all 0.3s;
}
.contact-us:hover::before {
  opacity: 0 ;
  transform: scale(0.5,0.5);
}
.contact-us::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid rgba(231,201,201,0.7);
  transform: scale(1.2,1.2);
}
.contact-us:hover::after {
  opacity: 1;
  transform: scale(1,1);
}




/*УГОЛКИ*/
.corner {
  position: absolute;
  width: 50px;
  height: 50px;
}

.corner-top-right {
  top: 10px;
  right: 10px;
  border-top: 1px solid #c1c1c1;
  border-right: 1px solid #c1c1c1;
}

.corner-top-left {
  top: 10px;
  left: 10px;
  border-top: 1px solid #c1c1c1;
  border-left: 1px solid #c1c1c1;
}

.corner-bottom-right {
  bottom: 10px;
  right: 10px;
  border-bottom: 1px solid #c1c1c1;
  border-right: 1px solid #c1c1c1;
}

.corner-bottom-left {
  bottom: 10px;
  left: 10px;
  border-bottom: 1px solid #c1c1c1;
  border-left: 1px solid #c1c1c1;
}

/*____*/

.box {
  background-color: #74706f;
  width: 100%;

  color: #fff;
  box-sizing: border-box;
  padding: 10px 10px 10px 0;
  position: relative;
}

.box-pink {
  border-bottom: 5px solid #e7c9c9 !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}


.location {
  color: #8a8a8a;
  font-size: 20px;
  line-height: 22px;
  display: inline-flex;
  flex-direction: row;
}

.location svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
}
.location p {
    margin-left: 10px;
}


.text-container {
  padding-left: 75px;
  padding-right: 75px;
  box-sizing: border-box;
  position: relative;
}



.title {
  color: #191b1c;
  font-size: 4rem;
  font-weight: 500;
  line-height: 72px;
}

.subtitle {
  color: #393939;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  text-align: right;

  order: 2;
  align-self: flex-end;
}

.numbers {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /*gap: 10px;*/
  justify-items: center;
  justify-content: center;
  text-align: center;
}


.image-circle {
  width: 210px;
  height: 210px;
  border-radius: 100000px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-shrink: 0;
  padding: 10px;
}


.service-item {
  color: #374151;
  align-items: flex-start;
  flex-direction: row;
  display: flex;
}

.service-icon {
  display: grid;
  place-items: center;
  background-color: #e7c9c9;
  color: black;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  aspect-ratio: 1;
}



.services {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  column-gap: 200px;
}

.center-obj {
  display: flex;
  justify-content: center;
  align-items: center;
}


.first-element {
  margin-top: 100px;
}

@media (max-width: 767px) {
  .title {
    font-size: 2rem;
    line-height: 2.5rem;
    color: white;
  }

  .subtitle {
    margin-top: 10px;
    text-align: left;
    color: white;
  }

  #main-img {
    display: none;
  }

  .main-header {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url("../assets/mainPage/image.webp");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }

  .services {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .text-container {
    padding-left: 10px;
    padding-right: 0;
  }

  .services {
    column-gap: 0;
  }

  .center-obj {
    align-items: start;
    justify-content: start;
  }

  .navbar-custom {
    height: 100px;
    flex-direction: column;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }

  .numbers {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    justify-content: start;

  }

  .first-element {
    margin-top: 120px !important;
  }

  .links {
    max-width: 100vw;

  }

  .link {
    font-size: smaller ;
    letter-spacing: 0;
  }

  .navbar-custom li {
    margin-left: 0.5rem;
  }


}

.footer {
  width: 100%;
  height: 100px;
  margin-bottom: 5%;
}

.gray-text {
  color: #8a8a8a!important;
}

@media (min-width: 800px) and (max-width: 1300px) {
  /* Применение стилей при ширине экрана от 800px до 1300px */
  .title {
    font-size: 3rem;
  }
  .subtitle {
    font-size: 14px;
  }
}